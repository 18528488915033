import React from "react";

import { GridHome, AreaLogo, AreaTitle, ImageLeft, CountdownArea, ImageRight, SectionLeft, SectionRight } from "./Styles";

import sectionLeft1 from '../../assets/section-left/1.png';
import sectionLeft2 from '../../assets/section-left/2.png';
import sectionLeft3 from '../../assets/section-left/3.png';
import sectionLeft4 from '../../assets/section-left/4.png';
import sectionLeft5 from '../../assets/section-left/5.png';

import sectionRight1 from '../../assets/section-right/1.png';
import sectionRight2 from '../../assets/section-right/2.png';
import sectionRight3 from '../../assets/section-right/3.png';
import sectionRight4 from '../../assets/section-right/4.png';
import sectionRight5 from '../../assets/section-right/5.png';

import BigLogo from '../../assets/BigLogo.png';
import Countdown from "react-countdown";

export default function HomePage() {
    const leftSectionImages = [sectionLeft1, sectionLeft2, sectionLeft3, sectionLeft4, sectionLeft5];
    const leftSectionImage = leftSectionImages[Math.floor(Math.random() * leftSectionImages.length)];

    const rightSectionImages = [sectionRight1, sectionRight2, sectionRight3, sectionRight4, sectionRight5];
    const rightSectionImage = rightSectionImages[Math.floor(Math.random() * rightSectionImages.length)];

    const Completionist = () => <span>Estamos quase lá!!!</span>;

    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            // Render a countdown
            return  <b>{days} dias {hours} horas {minutes} minutos {seconds} segundos</b>;
        }
    };

    return (        
        <GridHome>
            <SectionLeft>
                <AreaLogo>
                    <img src={BigLogo} alt="Instituto Anhangá" /> 
                </AreaLogo>
                <AreaTitle>
                    <h1><b>Em breve,</b> muita informação relevante para <span>você</span> e seus amiguinhos <span>felinos</span>!</h1>
                </AreaTitle>
                <ImageLeft>
                    <img src={leftSectionImage} alt="Imagem promocional de pet" /> 
                </ImageLeft>
            </SectionLeft> 
            <SectionRight>
                <CountdownArea>
                    <p>
                        <span>Disponível para uso em:</span><br></br>
                        <Countdown 
                            date={Date.parse('2023-03-01T21:00')} 
                            renderer={renderer}
                        />                    
                    </p>
                </CountdownArea>
                <ImageRight>
                    <img src={rightSectionImage} alt="Imagem promocional de pet" />  
                </ImageRight>
            </SectionRight>           
        </GridHome>                            
    );
  };
import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import GlobalStyles from "./styles/GlobalStyles";
import Layout from "./components/Layout/index";

export default function App() {
  return (
    <Router>
      <>
        <Layout />
        <GlobalStyles />
      </>
    </Router>
  );
}

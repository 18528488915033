import styled from "styled-components";

export const GridForm = styled.div`   
    margin-top: 200px;           
    h2 {
        font-size: 40px;
        padding: 16px 24px;
        @media screen and (max-width: 1000px) {
            width: 90%;
            text-align: center;
            padding: 16px 0px;
            margin: 0 auto;            
        }
    }
    p {
        font-size: 18px;
        width: 800px;
        padding: 8px 24px;
        @media screen and (max-width: 1000px) {
            width: 90%;
            text-align: center;
            padding: 16px 0px;
            margin: 0 auto;
        }
    }
    .dogCaramelo {
        position: absolute;
        right: 0px;
        top: 900px;
        @media screen and (max-width: 1000px) {
            display: none ;
        }
    }
    .containerForm {
        width: 600px; 
        padding-top: 20px;
        margin-left: 100px !important;
        margin-right: 0px !important;
        text-align: center;        
        img {
            margin-bottom: 20px;            
        }
        @media screen and (max-width: 1300px) {
            margin-left: 40px !important;
        }
        @media screen and (max-width: 1200px) {
            width: 400px; 
        }
        @media screen and (max-width: 1000px) {
            width: 90%;
            margin-left: auto !important;
            margin-right: auto !important;
            margin-bottom: 100px;
        }
        button {
            background: var(--color-primary);
            border: 1px solid var(--color-primary);            
        }
        button:active {
            background: #a62b54 !important;
            border: 1px solid #a62b54;            
        }
    } 
    footer {
        padding-top: 100px;
        text-align: center;  
        font-weight: bold;
        
    }   
`;
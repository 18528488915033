import styled from "styled-components";

export const GridHome = styled.div`    
    display: flex;   
    justify-content: space-between;   
    flex-wrap: wrap;           
`;

export const SectionLeft = styled.div`        
    flex: 1; 
    @media screen and (max-width: 900px) {
        width: 100%;        
    }   
    
`;
export const SectionRight = styled.div` 
    flex: 1;  
    @media screen and (max-width: 900px) {
        width: 100%;        
    }   
`;

export const AreaLogo = styled.div`        
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 30px;
    @media screen and (max-width: 900px) {
        padding-left: 0px;
        width: 100%;        
        text-align: center;        
    }
`;

export const AreaTitle = styled.div`            
    width: 630px;    
    h1 {
        font-size: 50px;
        line-height: 60px;
        font-weight: 400;
        padding-left: 30px;        
        span {
            color: var(--color-primary);
        }
        @media screen and (max-width: 1200px) {
            font-size: 40px;
            line-height: 50px;
        } 
    }   
    @media screen and (max-width: 1200px) {
        width: 400px;    
    } 
    @media screen and (max-width: 900px) {
        width: 80%;    
        margin: 0 auto;
    }
`;

export const ImageLeft = styled.div`        
    width: 100%;    
    padding-top: 20px;
    img {
        width: 590px;
        @media screen and (max-width: 1200px) {
            width: 450px;    
        } 
        @media screen and (max-width: 600px) {
            max-width: 90%;
        }
    }
    @media screen and (max-width: 900px) {
        text-align: center;
    }
`;

export const CountdownArea = styled.div`        
    p {
        text-align: right;
        font-size: 20px;
        color: var(--color-primary);
        padding-right: 34px;
        padding-top: 40px;
        b {
            font-size: 30px;
            color: #212121;
            @media screen and (max-width: 1380px) {
                font-size: 20px;
            }
        } 
        @media screen and (max-width: 900px) {
            text-align: center;            
        }       
    }       
`;

export const ImageRight = styled.div`    
    text-align: right;
    img {
        width: 450px;
        @media screen and (max-width: 950px) {
            width: 400px;
        } 
        @media screen and (max-width: 600px) {
            max-width: 90%;
        }       
    }
    @media screen and (max-width: 900px) {
        text-align: center;
    }   
    @media screen and (max-width: 600px) {
        max-width: 100%;
        width: 600px;
    }
`;
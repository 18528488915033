import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import roles from './roles.json';

import BigLogo from '../../assets/BigLogo.png';

export default function ContactForm() {
  const { values, result, setResult, handleChange, handleSubmit } = useForm();
  const rolesComponents = roles.map(role => <option key={role.value} value={role.value}>{role.caption}</option>);
  return (
    <>
      <Container className="containerForm">
        <img src={BigLogo} alt="Imagem de Pet" /> 
        <Row className="justify-content-md-start">
          <Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Row} controlId="formContactName">
                {/* <Form.Label column sm={2}>Nome</Form.Label> */}
                <Col>
                  <Form.Control autoComplete="off" placeholder="Nome"
                    name="name" value={values.name} onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formContactEmail">
                {/* <Form.Label column sm={2}>Email</Form.Label> */}
                <Col>
                  <Form.Control autoComplete="off" placeholder="Email"
                    name="email" value={values.email} onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formContactPhone">
                {/* <Form.Label column sm={2}>Telefone</Form.Label> */}
                <Col>
                  <Form.Control autoComplete="off" placeholder="Telefone"
                    name="phone" value={values.phone} onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              {/*
              <Form.Group as={Row} controlId="formContactRole">                
                <Col> 
                  <Form.Control as="select" name="role" value={values.role} onChange={handleChange}>
                    <option key="0" value="0">Você é...</option>
                    {rolesComponents}
                  </Form.Control>
                </Col>
              </Form.Group>
              */}
              <Form.Group as={Row} controlId="formContactDescription">
                {/* <Form.Label column sm={2}>Mensagem</Form.Label> */}
                <Col>
                  <Form.Control as="textarea" placeholder="Por que você quer ser um usuário beta?" rows={5}
                    name="message" value={values.message} onChange={handleChange}
                  />
                </Col>
              </Form.Group>              
              <Row className="">
                <Col>
                  <Button type="submit" block>Enviar</Button>
                </Col>
              </Row>              
            </Form>
          </Col>
        </Row>
      </Container>
      <Modal show={result} onHide={() => { setResult(null) }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{result && result.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{result && result.message}</Modal.Body>
      </Modal>
    </>
  );
}

const emptyData = {name: "", message: "", email: "", phone:"", role:"0",};

const useForm = () => {
  const [values, setValues] = useState(emptyData);
  const [result, setResult] = useState(null);

  const handleChange = (event) => {
      const auxValues = { ...values }
      auxValues[event.target.name] = event.target.value;
      setValues(auxValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let invalidFields = [ !values.email ? 'email' : '', values.role === 0 ? 'papel' : '', !values.message ? 'mensagem' : ''];
    let invalidFieldsString = invalidFields.filter(field => field).join(', ');
    if (invalidFieldsString) {
      setResult({title: "Formulário", message: `Os seguintes campos devem ser preenchidos: ${invalidFieldsString}`});
      return;
    }
    try {
        let method = "POST";
        let url = "https://nu1f4awdna.execute-api.sa-east-1.amazonaws.com/dev/public/v1/contacts";
        let contact = {
            email: values.email,
            name: values.name, 
            phone: values.phone,
            message: values.message,
            role: 'Projeto Gato +'
        };
        fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contact)
        }).then(
            res => {
                switch (res.status) {
                    case 200:
                    case 201:
                        return res.json();
                    default:
                        res.text().then((text) => console.log(text));
                        throw Error("Não foi possível enviar a mensagem.");
                }
            }
        ).then(
            (contact) => {
              setValues(emptyData);
              setResult({title: "Mensagem enviada", message: "Temos surpresas vindo por aí... Ficamos muito felizes com o seu interesse. Em breve entraremos em contato."});
            },
            (error) => {
                setResult({title: "Erro", message: error.message});
            }
        );
    } catch (error) {
        setResult({title: "Erro", message: error.message});
    }
  };

  return {values, setValues, result, setResult, handleChange, handleSubmit};
};
import React from "react";

import { Container, Wrapper } from "./styles";

import ContactPage from '../ContactPage/index';
import HomePage from '../HomePage/index';

export default function Layout() {
    return (
        <Container>
            <Wrapper>                
                <HomePage />
                <ContactPage />
            </Wrapper>
        </Container>
    );
  };
import React from "react";

import { GridForm } from "./Styles";

import contactPageImage1 from '../../assets/contact-page/1.png';
import contactPageImage2 from '../../assets/contact-page/2.png';
import contactPageImage3 from '../../assets/contact-page/3.png';
import contactPageImage4 from '../../assets/contact-page/4.png';
import contactPageImage5 from '../../assets/contact-page/5.png';

import ContactForm from "./ContactForm";


export default function ContactPage() {
    const contactPageImages = [contactPageImage1, contactPageImage2, contactPageImage3, contactPageImage4, contactPageImage5];
    const contactPageImage = contactPageImages[Math.floor(Math.random() * contactPageImages.length)];
    return (
        <GridForm>
            <h2><b>Quer ser um usuário Beta?</b></h2>
            <p>
                Queremos construir juntos
                um mundo melhor para todos, humanos e pets.
                Embarque nessa com a gente!
            </p>            
            <ContactForm/>
            <img className="dogCaramelo" src={contactPageImage} alt="Imagem de Pet" /> 
            <footer>Projeto Gato+ - Instituto Anhangá</footer>
        </GridForm>
    );
}
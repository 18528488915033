import styled from "styled-components";

export const Container = styled.div`    
    
`;

export const Wrapper = styled.div`
  max-width: 1660px;  
  margin: 0 auto;
  padding: 0 16px;    

`;
